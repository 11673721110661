import gsap from "gsap/all";
import { qs, qsa } from "../utils/lib";

export default class globalAccordionInit {
    constructor(el) {
        this.accordion = el;
        this.summary = this.accordion.firstElementChild;
        this.detail = this.accordion.lastElementChild;
        this.arrow = qs(".js-accordion-arrow", el);
        this.toggl = qs('.engraving_toggl', el)
        this.isOpen = false;
    }
    init() {
        if (this.accordion.classList.contains("js-only-mobile-accordion")) {
            this.initAccordionIfMobile();
            window.addEventListener("resize", () => {
                this.resetAccordionToMobile();
                this.resetAccordionToDesktop();
            });
        } else {
            this.initAccordion();
        }

        if (this.accordion.classList.contains("film-navigation")) {
            const anchors = qsa(".film-navigation__anchor", this.accordion);
            anchors.forEach((anchor) => {
                anchor.addEventListener("click", () => {
                    if (window.innerWidth < 1024) {
                        this.handleArrow(true);
                        gsap.to(this.detail, {
                            height: 0,
                            autoAlpha: 0,
                            duration: 0.2,
                            ease: "Circ.easeInOut",
                            onComplete: () => {
                                this.isOpen = false;
                            },
                        });
                    }
                });
            });
        }
    }

    // Only Mobile Accordion Logic
    resetAccordionToDesktop() {
        if (window.innerWidth > 1024) {
            gsap.set(this.detail, {
                height: "auto",
                autoAlpha: 1,
                duration: 0,
            });
            this.isOpen = false;
        }
    }

    resetAccordionToMobile() {
        if (window.innerWidth < 1024) {
            gsap.set(this.detail, {
                height: 0,
                autoAlpha: 0,
                duration: 0,
            });
            this.isOpen = false;
        }
    }

    initAccordionIfMobile() {
        if (window.innerWidth < 1024) {
            this.initAccordion();
        }
    }

    // Standard Accordion Logic
    initAccordion() {
        this.handleArrow(true);
        const isCheckout = this.accordion.dataset.checkout == "true";
        const isMobile = window.innerWidth < 768;
        const isOpen = isCheckout && isMobile;
        gsap.set(this.detail, {
            height: isOpen ? "auto" : 0,
            autoAlpha: isOpen ? 1 : 0,
            duration: 0,
        });
        this.isOpen = isOpen;
        this.summary.addEventListener("click", this.toggleAccordion.bind(this));
    }

    toggleAccordion() {
        this.handleArrow(this.isOpen);
        if (this.toggl) {
            this.toggl.classList.toggle("active")
        }
        gsap.to(this.detail, {
            height: this.isOpen ? 0 : "auto",
            autoAlpha: this.isOpen ? 0 : 1,
            duration: 0.3,
            ease: "Circ.easeInOut",
            onComplete: () => {
                this.isOpen = !this.isOpen;
            },
        });
    }

    handleArrow(condition) {
        if (this.arrow) {
            condition
                ? this.arrow.classList.remove("opened")
                : this.arrow.classList.add("opened");

        }
    }
}

export const globalAccordion = () => {
    if (qs(".js-accordion")) {
        const accordions = qsa(".js-accordion");

        accordions.forEach((accordion) => {
            const accInit = new globalAccordionInit(accordion);
            accInit.init();
        });
    }
};
