import gsap from "gsap";
import { qs, qsa } from "../utils/lib";
import _ from "lodash";

export default class MiniCart {
  constructor() {
    this.cont = qs(".minicart_cont");
    this.close_btn = qs(".minicart_close");
    this.btns = qsa(".js-add-to-cart");
    this.layer = qs(".minicart_layer");
    this.products_cont = qs(".minicart_products-cont");
    this.btn = qsa(".js-open-cart");
    this.count_el = qsa(".shopping_cart_count");
    this.count = this.count_el[0].innerHTML;
    this.cart_url = qs("body").dataset.cart;
    this.info_url = qs("body").dataset.info;
    this.upsell = qs('.js-upsell')
    this.checkout = this.init();
    this.total = qs(".cart-total")
    this.promo = qs(".promo_text")
    this.engraving_toggl = qs('.engraving_toggl')
  }

  init() {
    gsap.to(this.cont, {
      x: "100%",
      duration: 0,
    });
    this.btn.forEach((btn) => {
      btn.addEventListener("click", () => {
        if (this.count == 0) {
          return false;
        }

        this.updateCart();
        this.open();
      });
    });
    this.btns.forEach((btn) => {
      btn.addEventListener("click", (e) => {
        e.preventDefault();
        this.addToCart(btn);
      });
    });
    if (this.upsell) {
      this.upsell.addEventListener("click", (e) => {
        e.preventDefault()
        this.addUpsell(this.upsell)
      })
    }
    this.close_btn.addEventListener("click", () => {
      this.close();
    });
    this.layer.addEventListener("click", (e) => {
      if (e.target == this.layer) {
        this.close();
      }
    });
  }

  getCurrentVariant(target) {
    const t = qs(target);
    this.option_1 = qs(".option_1", t).value;
    this.option_2 = qs(".option_2", t).value;
    this.option_3 = qs(".option_3", t).value;
  }

  updateCount() {
    this.count_el.forEach((el) => {
      el.innerHTML = this.count;
      if (this.count == 0) {
        el.classList.add("hidden");
      } else {
        el.classList.remove("hidden");
      }
    });
  }
  open() {
    this.layer.classList.add("open");
    gsap.to(this.cont, {
      x: 0,
      duration: 0.4,
    });
  }

  close() {
    gsap.to(this.cont, {
      x: "100%",
      duration: 0.4,
      onComplete: () => {
        this.layer.classList.remove("open");
      },
    });
  }
  updateCart() {
    fetch(this.cart_url)
      .then((response) => {
        // The API call was successful!
        return response.text();
      })
      .then((html) => {
        if (html.indexOf("data-removed") > 0) {
          location.reload()
        }
        this.products_cont.innerHTML = html;
        this.cartActions();
      })
      .catch((err) => {
        // There was an error
        console.warn("Something went wrong.", err);
      });
    fetch(this.info_url)
      .then((response) => {
        // The API call was successful!
        return response.json();
      })
      .then((json) => {
        console.log(json)
        if (this.promo) {
          this.promo.classList.remove("ok")
          this.promo.classList.remove("ko")
          const target_class = json.promo;
          this.promo.classList.add(target_class ? "ok" : "ko")
        }

        this.total.innerHTML = json.total;
      })
      .catch((err) => {
        // There was an error
        console.warn("Something went wrong.", err);
      });
  }

  cartActions() {
    const removes = qsa(".js_remove_item");
    removes.forEach((el, i) => {
      el.addEventListener("click", () => {
        this.removeItem(el, i);
      });
    });
    const adds = qsa(".js-plus");
    adds.forEach((el, i) => {
      el.addEventListener("click", () => {
        this.removeItem(el, i);
      });
    });
    const less = qsa(".js-minus");
    less.forEach((el, i) => {
      el.addEventListener("click", () => {
        this.removeItem(el, i);
      });
    });
  }

  removeItem(item, i) {
    let obj = {
      index: i,
    };
    this.postData(item.dataset.url, obj).then((data) => {
      this.count = data.item_count;
      this.updateCount();
      this.updateCart();
      if (this.count == 0) {
        this.close();
      }
    });
  }

  addUpsell(item) {
    const id = item.dataset.id;
    const qty = 1

    let obj = {
      id: id,
      quantity: 1,
    };
    this.postData(item.dataset.url, obj).then((data) => {

      this.count = data.item_count;
      this.updateCount();
      this.updateCart();
      const el = qs('.also-add')
      el.remove()
    });
  }

  addToCart(item) {
    const target = item.dataset.target;
    const qty = qs("#qty").value;
    this.getCurrentVariant(target);
    const variants = JSON.parse(item.dataset.variants);
    // console.log(variants);
    const variant = _.find(variants, {
      option1: this.option_1,
      option2: this.option_2,
      option3: this.option_3,
    });

    let obj = {
      id: variant.id,
      quantity: parseInt(qty),
    };
    if (this.engraving_toggl) {
      const cont = qs(".product_engraving")
      const inputs = qsa('.engraving_input')
      let message;
      let str = [];
      inputs.forEach((input) => {
        if (input.value) {
          str.push(input.value)
        }
      })
      if (str.length > 0 && this.engraving_toggl.classList.contains("active")) {
        message = str.join("|")
        obj["message"] = message
        obj["engraving"] = cont.dataset.font
      }
    }
    this.postData(item.dataset.url, obj).then((data) => {
      if (typeof gtag !== "undefined" && variant) {
        gtag("event", "add_to_cart", {
          items: [variant.google],
        });
        gtag("event", "add", {
          event_category: "cart",
        });
      }
      this.count = data.item_count;
      this.updateCount();
      this.updateCart();
      this.open();
    });
  }

  async postData(url = "", data = {}) {
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });
    return response.json();
  }
}
